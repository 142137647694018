<template>

  <div class="row">
    <div class="col-12 card">
      <h4>{{ report_title }}</h4>
      <router-link :to="{path: '/reports/appeal/8' }"><h5><span class="icon-ArrowLeft"></span> Назад к Отчётам </h5></router-link>
      <div class="row">
        <div class="col-6">
          <div class="col-12 top-24">
            <div class="text-field">
              <label class="text-field__label" for="search">Номер жалобы</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search"  v-model="currentAppeal.id" disabled="True">
              </div>
            </div>
          </div>
          <div class="col-12 top-24" >
            <div class="text-field">
              <label class="text-field__label" for="search">CID</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" v-model="currentAppeal.cid" disabled="True">
              </div>
            </div>
          </div>
          <div class="col-12 top-24">
            <div class="text-field">
              <label class="text-field__label" for="search">Адрес контейнерной площадки</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search"  disabled="True">
              </div>
            </div>
          </div>
          <div class="col-12 top-24">
            <div class="text-field">
              <label class="text-field__label" for="search">Дата создания жалобы</label>
              <div class="text-field__icon">
                <input class="text-field__input" type="search" name="search" id="search" v-model="currentAppeal.created_at" disabled="True">
              </div>
            </div>
          </div>

        </div>
        <div class="col-6">
          <div class="col-12 top-24">
            <div class="text-field">
              <label class="text-field__label" for="search">Фотография</label>
              <div class="text-field__icon">
                <!-- <input class="text-field__input" type="search" name="search" id="search"  v-model="currentAppeal.id" disabled="True"> -->
                <img v-if="currentAppeal.image != ''" :src="currentAppeal.image" width="80%">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      appealId: this.$route.params.id,
      report_title: '',
    }
  },
  computed: {
    currentAppeal() {
      return this.$store.state.appeals.find(appeal =>appeal.id == this.appealId);
    },
  },

};
</script>
